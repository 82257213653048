import React from 'react';
import ReactDOM from 'react-dom';

// Mock Server
import { makeServer } from './mock';

// Styles
import 'tailwindcss/dist/base.min.css';

// Fonts
import 'typeface-space-mono';
import 'typeface-barlow';

// App
import App from './App';

if (process.env.NODE_ENV === 'development') {
  makeServer({ environment: 'development' });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
