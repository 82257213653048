import React, { createContext, FC, useContext } from 'react';
import axios, { AxiosInstance } from 'axios';
import { AuthContext } from './AuthContext';

type FetchContextState = {
  authAxios: AxiosInstance;
};

const FetchContext = createContext<FetchContextState | undefined>(undefined);
const { Provider } = FetchContext;

const FetchProvider: FC = ({ children }) => {
  const authContext = useContext(AuthContext);

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  authAxios.interceptors.request.use(
    config => {
      config.headers.Authorization = `Bearer ${authContext?.authState.token}`;
      return config;
    },
    error => Promise.reject(error)
  );

  return (
    <Provider
      value={{
        authAxios,
      }}
    >
      {children}
    </Provider>
  );
};

export { FetchContext, FetchProvider };
