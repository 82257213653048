import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Types
import type { FC } from 'react';

type UserInfo = {
  firstName: string;
  lastName: string;
  email: string;
  id: number;
};

type AuthState = {
  token: string | null;
  userInfo: UserInfo | null;
};

type AuthContextState = {
  authState: AuthState;
  setAuthState: (authInfo: AuthState) => void;
  isAuthenticated: () => boolean;
  logout: () => void;
};

const AuthContext = createContext<AuthContextState | undefined>(undefined);
const { Provider } = AuthContext;

const AuthProvider: FC = ({ children }) => {
  const history = useHistory();
  const token = localStorage.getItem('token');
  const userInfo = localStorage.getItem('userInfo');

  const [authState, setAuthState] = useState<AuthState>({
    token: token ? token : null,
    userInfo: userInfo ? JSON.parse(userInfo) : {},
  });

  const setAuthInfo = ({ token, userInfo }: AuthState) => {
    localStorage.setItem('token', token!);
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    setAuthState({ token, userInfo });
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    setAuthState({ token: null, userInfo: null });
    history.replace('login');
  };

  const isAuthenticated = () => {
    if (!authState.token) return false;
    return !!authState.token;
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        isAuthenticated,
        logout,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
