import React, { FC, useContext, lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteProps,
} from 'react-router-dom';

// Context
import { FetchProvider } from './context/FetchContext';
import { AuthContext, AuthProvider } from './context/AuthContext';

// Components
const LoginPage = lazy(() => import('./pages/Unauthenticated/Login'));
const RegisterPage = lazy(() => import('./pages/Unauthenticated/Register'));
const DashboardPage = lazy(() => import('./pages/Authenticated/Dashboard'));
const StatisticsPage = lazy(() => import('./pages/Authenticated/Statistics'));
const ClientsPage = lazy(() => import('./pages/Authenticated/Clients'));
const InvoicesPage = lazy(() => import('./pages/Authenticated/Invoices'));

const AuthenticatedRoute: FC<RouteProps> = ({ children, ...props }) => {
  const authContext = useContext(AuthContext);

  return (
    <Route
      {...props}
      render={() =>
        authContext?.isAuthenticated() ? children : <Redirect to="/login" />
      }
    />
  );
};

const UnauthenticatedRoute: FC<RouteProps> = ({ children, ...props }) => {
  const authContext = useContext(AuthContext);

  return (
    <Route
      {...props}
      render={() =>
        authContext?.isAuthenticated() ? <Redirect to="/dashboard" /> : children
      }
    />
  );
};

const AppRoutes = () => {
  const authContext = useContext(AuthContext);

  const unauthenticatedRoutes = [
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/register',
      element: <RegisterPage />,
    },
  ];

  const authenticatedRoutes = [
    {
      path: '/dashboard',
      element: <DashboardPage />,
    },
    {
      path: '/statistics',
      element: <StatisticsPage />,
    },
    {
      path: '/clients',
      element: <ClientsPage />,
    },
    {
      path: '/invoice',
      element: <InvoicesPage />,
    },
  ];
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route
          path="/"
          exact
          render={() =>
            authContext?.isAuthenticated() ? (
              <Redirect to="/dashboard" />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        {unauthenticatedRoutes.map(route => (
          <UnauthenticatedRoute key={`route-${route.path}`} path={route.path}>
            {route.element}
          </UnauthenticatedRoute>
        ))}
        {authenticatedRoutes.map(route => (
          <AuthenticatedRoute key={`route-${route.path}`} path={route.path}>
            {route.element}
          </AuthenticatedRoute>
        ))}
      </Switch>
    </Suspense>
  );
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <FetchProvider>
          <AppRoutes />
        </FetchProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
